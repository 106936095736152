import { User } from 'src/app/utils/user.model';

export type NotifCategoryTabType =
   | 'ALL'
   | 'CB'
   | 'CB_PACK'
   | 'PUBLICATION'
   | 'INTERVIEW_REQUEST'
   | 'POST'
   | 'FOLLOW'
   | 'ASSIGNMENT'
   | 'REFERENCE'
   | 'MESSAGE'
   | 'PRODUCT'
   | 'LIKE'
   | 'COMMENT';

export enum NotificationType {
   RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
   RECEIVE_LIKE = 'RECEIVE_LIKE',
   RECEIVE_COMMENT_LIKE = 'RECEIVE_COMMENT_LIKE',
   RECEIVE_COMMENT = 'RECEIVE_COMMENT',
   RECEIVE_COMMENT_REPLY = 'RECEIVE_COMMENT_REPLY',
   RECEIVE_CB = 'RECEIVE_CB',
   RECEIVE_FOLLOWED = 'RECEIVE_FOLLOWED',
   RECEIVE_QUIZ_ANSWERED = 'RECEIVE_QUIZ_ANSWERED',
   RECEIVE_QUIZ_ERROR_REPORT = 'RECEIVE_QUIZ_ERROR_REPORT',
   MENTION_RECEIVED = 'MENTION_RECEIVED',
   RECEIVE_ROLE_ASSIGNMENT = 'RECEIVE_ROLE_ASSIGNMENT',
   RECEIVE_REFERENCE_REQUEST = 'RECEIVE_REFERENCE_REQUEST',
   RECEIVE_REFERENCE_FEEDBACK = 'RECEIVE_REFERENCE_FEEDBACK',
   RECEIVE_QUIZ_CHALLENGE = 'RECEIVE_QUIZ_CHALLENGE',
   FRIEND_INVITED = 'FRIEND_INVITED',
   GAIN_CB = 'GAIN_CB',
   GAIN_CB_PACK = 'GAIN_CB_PACK',
   GAIN_CB_BY_FRIEND = 'GAIN_CB_BY_FRIEND',
   RECEIVE_STORY_LIKE = 'RECEIVE_STORY_LIKE',
   PACKAGE_GIVEN = 'PACKAGE_GIVEN',
   CB_REQUEST = 'CB_REQUEST',
   PRODUCT_ORDER_STATUS = 'PRODUCT_ORDER_STATUS',
   PURCHASE_PRODUCT = 'PURCHASE_PRODUCT',
   PURCHASE_PRODUCT_PROFIT = 'PURCHASE_PRODUCT_PROFIT',
   GIFT_FOR_INVITATION = 'GIFT_FOR_INVITATION'
}

export interface NotificationResponse {
   content: Notification[];
   page: {
      number: number;
      size: number;
      totalElements: number;
      totalPages: number;
   };
}

export interface Notification {
   typeTitle: string;
   toUser: number;
   content: string;
   type: NotificationType;
   fromUser: number;
   quizId: string | null;
   id: number;
   seen: boolean;
   createdAt: string;
   postId: string | null;
   typeId: number;
   userInfo?: User;
}
